import React, { useEffect, useState } from "react";

// Contexts

// Functions

//Componentes
import FaleConosco from "../FaleConosco";

//Chamada da api

// Interno

//Assets
import Hero1920 from "../../assets/img/_files/hero-1920.jpg";
import Hero768 from "../../assets/img/_files/hero-768.jpg";
import Hero375 from "../../assets/img/_files/hero-375.jpg";
import Floresta from "../../assets/img/floresta.jpg";

// Styles
import "./styles.scss";

export default function QuemSomos() {
  const [translateY, setTranslateY] = useState(0);

  // Aplica o efeito de paralaxe ao elemento picture
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const newTranslateY = scrollTop * 0.7; // Ajuste conforme necessário

      setTranslateY(newTranslateY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [translateY]);

  return (
    <div>
      <main className="site-main">
        <section className="main-hero">
          <picture className="main-hero__media" style={{ transform: `translate(0px, ${translateY}px)` }}>
            <source media="(min-width: 1024px)" srcSet={Hero1920} />
            <source media="(min-width: 768px)" srcSet={Hero768} />
            <img src={Hero375} alt="Planta germinando" />
          </picture>

          <div className="main-hero__content" style={{ transform: `translate(0px, ${translateY}px)` }}>
            <h1>Safe<br />Carbon</h1>
          <h2>dedicada  à<br /> descarbonização<br /> do planeta</h2>
            {/* <button className="btn btn-contato" onClick={() => window.$('#faleconosco').modal('show')}>Fale Conosco</button> */}
          </div>
        </section>

        <div className="wrapper-sections">

          <div className="wrapper-sections__image">
            <div className="wrapper-sections__pic">
              <img src={Floresta} alt="..." />
            </div>
          </div>

          <section className="about" id="quemsomos">
            <div className="wrap">
              <div className="col-text">
                <h2 className="title">Quem<br /> Somos</h2>
                <h3 className="subtitle">Desenvolvemos projetos que visam a descarbonização do meio ambiente, contribuindo com
                  o combate às mudanças climáticas e a desaceleração do aquecimento global.</h3>
                <div className="editor">
                  <p>A SafeCarbon está comprometida a implementar<br />
                    a jornada completa pela descarbonização,<br />
                    mediante o desenvolvimento de inovações<br />
                    tecnológicas e soluções SOCIOAMBIENTAIS.</p>
                </div>
              </div>
              {/* <div className="col-logos">
          <img src={SafecarbonLogo} alt="Logo SafeCarbon" />
          <img src={InstitutoSafewebLogo} alt="Logo Instituto SafeWeb" />
        </div> */}
            </div>
          </section>

          <section className="our-mission" id="nossa-missao">
            <div className="wrap">
              <div className="col-text">
                <h2 className="title-accent">Nossa <span>Missão</span></h2>
                <div className="editor">
                  <p>Executar projetos que visam a descarbonização, trabalhando sempre
                    com idoneidade e transparência, promovendo o desenvolvimento socioeconômico das
                    comunidades envolvidas nos projetos e alavancando o
                    nome da empresa e seus parceiros através de uma gestão integra e comprometida com o meio ambiente.</p>
                </div>
              </div>
            </div>
          </section>

          <section className="carbon-credit" id="creditocarbono">
            <div className="wrap">
              <div className="col-text">
                <h2 className="title">Crédito<br /> Carbono</h2>
                <div className="editor">
                  <p>
                    O <em><strong>Crédito de Carbono</strong></em> é um ativo não só financeiro como também
                    ambiental, com o objetivo de <em><strong>incentivar a redução das emissões</strong></em> de gases<br />
                    de efeito estufa por parte de <em><strong>pessoas, empresas, organizações e países.</strong></em>
                  </p>
                </div>
              </div>
              <div className="col-card">
                <div className="carbon-credit__icon">
                  <svg width="94" height="63" viewBox="0 0 94 63" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity=".85" fill="#F5F5F5">
                      <path fillRule="evenodd" clipRule="evenodd"
                        d="M56.099 2.378c-8.556 0-15.736 5.879-17.7 13.811a1.189 1.189 0 01-1.554.836 11.43 11.43 0 00-3.809-.649c-5.95 0-10.834 4.551-11.348 10.353a1.189 1.189 0 01-1.585 1.016 13.243 13.243 0 00-4.438-.76c-7.336 0-13.282 5.934-13.282 13.256s5.946 13.257 13.282 13.257h21.448c.07-.08.151-.17.24-.269.394-.43.966-1.022 1.693-1.678 1.448-1.306 3.546-2.9 6.1-3.97 2.564-1.077 5.622-1.638 8.93-.81 3.31.83 6.727 3.013 10.073 7.172.35.434.35 1.053 0 1.488-3.345 4.159-6.763 6.343-10.072 7.171-3.308.829-6.367.268-8.931-.808-2.554-1.071-4.652-2.665-6.1-3.97a24.746 24.746 0 01-1.933-1.948H15.665C7.013 55.876 0 48.876 0 40.241s7.013-15.635 15.665-15.635c1.33 0 2.62.166 3.854.479a13.77 13.77 0 0113.517-11.087c1.169 0 2.303.146 3.387.418C39.044 6.061 46.86 0 56.099 0c11.387 0 20.616 9.215 20.616 20.58 0 1.423-.145 2.814-.422 4.159.669-.088 1.35-.133 2.042-.133 8.65 0 15.665 7.002 15.665 15.635 0 8.634-7.015 15.635-15.665 15.635h-7.496a1.19 1.19 0 110-2.378h7.496c7.334 0 13.283-5.936 13.283-13.257 0-7.32-5.949-13.257-13.283-13.257-1.162 0-2.286.15-3.359.429a1.193 1.193 0 01-1.16-.328 1.187 1.187 0 01-.28-1.171c.518-1.685.796-3.477.796-5.334 0-10.052-8.163-18.202-18.233-18.202zM40.443 55.876l.2.183c1.33 1.199 3.203 2.61 5.426 3.543 2.213.928 4.737 1.368 7.429.694 2.511-.63 5.292-2.26 8.174-5.609-2.882-3.35-5.663-4.98-8.175-5.609-2.692-.674-5.216-.234-7.428.694-2.224.933-4.096 2.344-5.425 3.543-.069.062-.136.123-.2.183h9.999a1.19 1.19 0 110 2.378h-10z" />
                      <path
                        d="M26.363 30.624c0-4.59 3.689-8.174 8.43-8.174 2.419 0 4.694 1.028 6.109 2.725.335.383.503.765.503 1.196 0 .79-.67 1.458-1.509 1.458-.455 0-.886-.215-1.245-.622-1.03-1.196-2.323-1.768-3.856-1.768-2.97 0-5.1 2.175-5.1 5.212s2.132 5.164 5.1 5.164c1.51 0 2.78-.574 3.833-1.768.384-.407.791-.622 1.27-.622a1.47 1.47 0 011.484 1.458c0 .383-.143.764-.504 1.196-1.39 1.697-3.664 2.725-6.108 2.725-4.742 0-8.407-3.586-8.407-8.174v-.006zm33.94 0c0 4.565-3.713 8.175-8.43 8.175s-8.383-3.586-8.383-8.175c0-4.588 3.688-8.174 8.383-8.174 4.696 0 8.43 3.61 8.43 8.174zm-13.484 0c0 3.036 2.107 5.188 5.077 5.188 2.97 0 5.078-2.175 5.078-5.188 0-3.012-2.132-5.187-5.078-5.187-2.945 0-5.077 2.152-5.077 5.187zm24.02 9.118c0 .572-.434.99-1.022.99l-5.56.015a1.036 1.036 0 01-1.037-1.035c0-.325.139-.619.434-.867l3.127-2.643c1.147-.973 1.532-1.592 1.532-2.426 0-.913-.557-1.5-1.409-1.5-.759 0-1.3.494-1.409 1.284-.016.155-.046.308-.062.448-.077.556-.495.912-1.038.912s-.991-.417-1.038-1.02c-.03-.2-.016-.479.03-.773.28-1.622 1.735-2.783 3.578-2.783 2.029 0 3.5 1.407 3.5 3.323 0 1.33-.666 2.412-2.43 3.879l-1.456 1.221h3.237c.587 0 1.021.417 1.021.973l.002.002z" />
                    </g>
                  </svg>
                </div>
                <div className="carbon-credit__desc" id="calculadora-de-carbono">
                  <p>Um crédito de carbono é uma unidade de medida que representa
                    a redução de uma tonelada métrica de dióxido de carbono (CO2) ou de gases de
                    efeito estufa equivalentes (GEE) de
                    uma atividade ou projeto específico durante um certo período de tempo.</p>
                </div>
              </div>
            </div>
          </section>

          <section className="carbon-calculator">
            <div className="wrap">
              <div className="col-text">
                <h2 className="title-accent">Calcule sua pegada de carbono</h2>
                <div className="editor">
                  <p>A pegada de carbono é uma medida que calcula a emissão de carbono equivalente na atmosfera por
                    uma pessoa, atividade, evento, empresa, organização ou governo.</p>
                </div>
              </div>
              <div className="col-actions">
                <a href="https://unfccc.int/documents/271269" target="_blank" rel="noopener noreferrer" className="btn btn--primary">Calculadora pessoa
                  jurídica</a>
                <a href="https://www.lifestylecalculator.com/unfccc" target="_blank" rel="noopener noreferrer" className="btn btn--outline-light">Calculadora pessoa
                  física</a>
              </div>
            </div>
          </section>

        </div>

      </main>

      {/* Modal Fale Conosco
      <div className="modal fade show" id="faleconosco" tabIndex="-1" aria-modal="true">
        <div className="modal-dialog modal-dialog-centered">
          <FaleConosco />
        </div>
      </div> */}
    </div>
  );
}
