import React from "react";

// Contexts

// Functions

//Componentes
import { Swiper, SwiperSlide } from "../../components/Swiper";

//Chamada da api

//Externos

// IMGs
import InstitutoSafe from "../../assets/img/logos/instituto-safeweb.png";
import Pasco from "../../assets/img/logos/pasco.png";
import Kanematsu from "../../assets/img/logos/kanematsu.png";
import BrazilCarbon from "../../assets/img/logos/brazil-carbon.jpg";
import Argustech from "../../assets/img/logos/argustech.png";

// Styles e Componentes Internos
import "./styles.scss";

export default function Faq() {
  return (
    <div id="faq">
      <section className="faq">
        <section className="row parceiros">
          <div className="col-12 parceiros-logo-container">
            <div className="d-flex justify-content-center" style={{ paddingBottom: "50px" }}>
              <h2 className="parceiros-title">Nossos Parceiros</h2>
            </div>
            <Swiper
              spaceBetween={5}
              slidesPerView={5}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                400: {
                  slidesPerView: 2,
                },
                639: {
                  slidesPerView: 3,
                },
                880: {
                  slidesPerView: 3,
                },
                1000: {
                  slidesPerView: 4,
                },
                1500: {
                  slidesPerView: 5,
                },
                1700: {
                  slidesPerView: 5,
                }
              }}
              className="parceiros-group d-flex justify-content-center"
            >
              <SwiperSlide style={{ marginRight: "50px" }}>
                <div className="parceiros-card">
                  <div className="parceiros-logo">
                    <img src={InstitutoSafe} alt="InstitutoSafe logo" />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide style={{ marginRight: "50px" }}>
                <div className="parceiros-card">
                  <div className="parceiros-logo">
                    <img src={BrazilCarbon} alt="BrazilCarbon logo" />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide style={{ marginRight: "50px" }}>
                <div className="parceiros-card">
                  <div className="parceiros-logo">
                    <img src={Pasco} alt="Pasco logo" />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide style={{ marginRight: "50px" }}>
                <div className="parceiros-card">
                  <div className="parceiros-logo">
                    <img src={Argustech} alt="Argustech logo" />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide style={{ marginRight: "50px" }}>
                <div className="parceiros-card">
                  <div className="parceiros-logo">
                    <img src={Kanematsu} alt="Kanematsu logo" style={{ marginTop: "20px" }} />
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </section>

        <div className="row wrapper mt-5">
          <h2 className="faq__title">FAQ <small>Perguntas Frequentes</small></h2>
          <div className="faq__content">
            <div className="faq-card">
              <h3 className="faq-card__title">O que é a UNFCC -Convenção das Nações Unidas sobre Mudança do Clima?</h3>
              <div className="faq-card__text">
                <p>Adotada em 1992, com o objetivo de alcançar a estabilização das concentrações de gases de efeito
                  estufa na atmosfera em um nível que impeça uma interferência antrópica perigosa no sistema
                  climático, em um prazo suficiente que permita aos ecossistemas adaptarem-se naturalmente à mudança
                  do clima, que assegure que a produção de alimentos não seja ameaçada e que permita ao
                  desenvolvimento econômico prosseguir de maneira sustentável, convenção no âmbito da qual REDD+ foi
                  negociado e é implementado.</p>
              </div>
            </div>
            <div className="faq-card">
              <h3 className="faq-card__title">Como funciona o Mercado de Crédito de Carbono?</h3>
              <div className="faq-card__text">
                <p>Um crédito de compensação é um instrumento para que governos e empresas alcancem as metas de
                  redução de carbono estabelecidas pelo Acordo de Paris. Indivíduos ou empresas que desejam compensar
                  as próprias emissões de gases de efeito estufa podem comprar esses créditos por meio de um
                  intermediário ou daqueles que capturam diretamente o carbono. Todo processo é certificado por
                  entidades independentes ou vinculadas à ONU.</p>
              </div>
            </div>
            <div className="faq-card">
              <h3 className="faq-card__title">O que são Gases de Efeito Estufa (GEE)?</h3>
              <div className="faq-card__text">
                <p>Constituintes gasosos da atmosfera, naturais ou antrópicos, que absorvem e reemitem radiação
                  infravermelha. Segundo o Protocolo de Quioto, incluem dióxido de carbono (CO2), metano (CH4), óxido
                  nitroso (N2O), hexafluoreto de enxofre (SF6), além de duas famílias de gases: hidrofluorcarbonos
                  (HFCs), perfluorcarbonos (PFCs).</p>
              </div>
            </div>
            <div className="faq-card">
              <h3 className="faq-card__title">O que é o Manejo sustentável de Florestas?</h3>
              <div className="faq-card__text">
                <p>Manejo da floresta para obtenção de benefícios econômicos, sociais e ambientais, respeitando-se os
                  mecanismos de sustentação do ecossistema objeto do manejo e considerando-se, cumulativa ou
                  alternativamente, a utilização de múltiplas espécies madeireiras, de múltiplos produtos e
                  subprodutos não madeireiros, bem como a utilização de outros bens e serviços florestais.</p>
              </div>
            </div>
            <div className="faq-card">
              <h3 className="faq-card__title">O que é descarbonização e qual a sua importância para o desenvolvimento sustentável?</h3>
              <div className="faq-card__text">
                <p>O gás carbônico (CO2) e o gás metano, entre outros, contribuem para um fenômeno natural chamado de efeito estufa. Apesar de esse termo ser muito associado a algo ruim, ele é, pelo contrário, o grande responsável pela manutenção da vida na Terra. Os gases de efeito estufa tem a capacidade de absorver parte radiação solar irradiada pela superfície terrestre, impedindo que haja fortes variações de temperatura. Com isso, eles ajudam a manter a temperatura habitável que temos.
                  O problema é que estamos produzindo muito mais desses gases hoje do que o necessário e mais até do que a
                  capacidade do próprio planeta de reabsorvê-lo e manter equilíbrio.
                  E qual é a consequência? A absorção de calor cresce e leva ao aquecimento global. Assim, o excesso da emissão de carbono na atmosfera torna-se um dos principais causadores das mudanças climáticas.
                  Com esse cenário crítico, como é possível fazer com que pessoas, empresas e países parem de usar combustíveis fósseis, desmatar as florestas, reciclar e reusar mais produtos e fazer escolhas mais sustentáveis em seu dia a dia?
                  Colocando metas que todos concordem e criando incentivos para persegui-las!
                  Dessa forma, diversos países se prontificaram em reduzir as emissões de gases de efeito estufa (GEE) por meio de compromissos internacionais (como: Acordo de Paris, Objetivo de Desenvolvimento Sustentável pela ONU, NET ZERO pela IEA e muito mais), na tentativa de frear o aquecimento global e as consequências trazidas por ele.
                  No Brasil não é diferente. A preocupação social pela crise climática vem crescendo a cada ano e uma das soluções encontradas para passar por esse desafio é investir em práticas de descarbonização
                  <br />
                  <br />
                  Fonte: Comer Energia</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
