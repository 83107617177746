import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

// Páginas
import CreditoCarbono from "../pages/CreditoCarbono";
import FaleConosco from "../pages/FaleConosco";
import Faq from "../pages/Faq";
import Home from "../pages/Home";
import PoliticaPrivacidade from "../pages/PoliticaPrivacidade";
import ProdutosServicos from "../pages/ProdutosServicos";
import QuemSomos from "../pages/QuemSomos";

// Componentes
import Erro404 from "../components/Errors";

export default function Router() {
  return (
    <main>
      <div id="raiz"></div>
      <React.StrictMode>
        <BrowserRouter>
          <Routes>
            <Route path="*" element={<Navigate to="/" replace />} />

            <Route path="/" element={<Home />} />
{/* 
            <Route path="/quemsomos" element={<QuemSomos />} />
            <Route
              path="/creditocarbono"
              element={<CreditoCarbono />}
            />
             <Route
              path="/produtosservicos"
              element={<ProdutosServicos />}
            />
            <Route path="/faq" element={<Faq />} />
            <Route
              path="/politicaprivacidade"
              element={<PoliticaPrivacidade />}
            />
            <Route path="/faleconosco" element={<FaleConosco />} /> */}
          
            <Route element={<Erro404 />} />
          </Routes>
        </BrowserRouter>
      </React.StrictMode>
    </main>
  );
}
