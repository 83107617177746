import React,{ useState } from "react";

// Contexts

// Functions

//Componentes

//Chamada da api

//Externos

//Assets
import Desenvolvimento from "../../assets/img/_files/desenvolvimento.jpg";
import Monitoramento from "../../assets/img/_files/monitoramento.jpg";
import Comercializacao from "../../assets/img/_files/comercializacao.jpg";
import Administracao from "../../assets/img/_files/administracao.jpg";
import Balanco from "../../assets/img/_files/balanco.jpg";

// Styles
import "./styles.scss";

export default function ProdutosServicos() {
  // Estado para acompanhar o índice do slide ativo para o primeiro bloco
  const [activeIndexFirstCarousel, setActiveIndexFirstCarousel] = useState(1);


  const handleFirstCarouselPrev = () => {
    setActiveIndexFirstCarousel(prevIndex => (prevIndex === 1 ? 5 : prevIndex - 1));
  };

  const handleFirstCarouselNext = () => {
    setActiveIndexFirstCarousel(prevIndex => (prevIndex === 5 ? 1 : prevIndex + 1));
  };


  return (
    <div id="produtosservicos">
      <section className="services" id="produtos-e-servicos">
        <div className="row wrapper">
          <div className="col-text">
            <h2 className="title">Produtos<br /> e serviços</h2>
            <div className="editor">
              <p>Atendemos<br /> pessoas, empresas e organizações na implementação da jornada completa pela
                descarbonização do meio ambiente.</p>
            </div>
          </div>

          <div className="col-slider">
            <div className="swiper services__slider carousel slide">
              <div className="carousel-inner">

                <div className={`carousel-item${activeIndexFirstCarousel === 1 ? 'active' : ''}`}>

                  <div className="service-card swiper-slide">
                    <div className="service-card__media">
                      <img src={Desenvolvimento} alt="..." />
                    </div>
                    <div className="service-card__content">
                      <h3 className="service-card__title">Desenvolvimento e Certificação de Projetos</h3>
                      <h4 className="service-card__subtitle">Projetos ambientais são ações e metas realizadas com
                        objetivo de preservar o meio ambiente, podendo ser realizadas por empresas, órgãos
                        públicos, instituições, associações entre outros.</h4>
                      <div className="service-card__text">
                        <p>Existem diversos tipos de projetos ambientais, porém, no que concerne a créditos de
                          carbono, podemos destacar o Redução de Emissões Por Desmatamento e Degradação Florestal
                          (REDD+). REDD+ são ações que se utilizam da biodiversidade e atividades sociais para
                          combater o desmatamento florestal, contribuindo para aumento e conservação dos estoques de
                          carbono florestal.</p>
                      </div>
                    </div>
                  </div>

                </div>
                <div className={`carousel-item${activeIndexFirstCarousel === 2 ? 'active' : ''}`}>
                  <div className="service-card swiper-slide">
                    <div className="service-card__media">
                      <img src={Monitoramento} alt="..." />
                    </div>
                    <div className="service-card__content">
                      <h3 className="service-card__title">Monitoramento de projetos via satélite</h3>
                      <h4 className="service-card__subtitle">Para a finalidade do projeto serão utilizados satélites
                        artificiais para monitoramento de áreas, com os seguintes objetivos:</h4>
                      <div className="service-card__text">
                        <ul>
                          <li>Identificar se ocorreram mudanças na área orbitada.</li>
                          <li>Identificar quais e como ocorreram essas mudanças.</li>
                          <li>Identificar quantitativa e qualitativamente as mudanças que eventualmente forem
                            apuradas.</li>
                        </ul>
                        <p>Assim, a tecnologia de monitoramento via satélite permite que informações sobre a área
                          monitorada sejam materializadas em textos, tabelas, mapas, entre outros, com objetivo de
                          aprofundar estudos de projetos aspectos ligados à agricultura, meio ambiente, etc, bem
                          como, imprimir credibilidade e segurança.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`carousel-item${activeIndexFirstCarousel === 3 ? 'active' : ''}`}>

                  <div className="service-card swiper-slide">
                    <div className="service-card__media">
                      <img src={Comercializacao} alt="..." />
                    </div>
                    <div className="service-card__content">
                      <h3 className="service-card__title">Comercialização de créditos de carbono</h3>
                      <h4 className="service-card__subtitle">Os créditos de carbono podem ser comercializados no mercado
                        regulado ou voluntário.</h4>
                      <div className="service-card__text">
                        <p>Tanto o mercado regulado como o voluntário comercializam os créditos de carbono a partir
                          da equivalência em toneladas de dióxido de carbono (ou gás carbônico) equivalente
                          (tCO2e), e os títulos de crédito podem ser negociados diretamente entre comprador e
                          vendedor ou de forma indireta no mercado secundário regulado pela bolsa de valores.<br />Cada
                          crédito possui um número de série único e todo o histórico de geração e emissão dos
                          créditos de carbono é público, podendo ser rastreado por meio de uma plataforma
                          eletrônica de registro, o que garante sua propriedade, veracidade e que este crédito seja
                          emitido e cancelado (ou aposentado) apenas uma vez.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`carousel-item${activeIndexFirstCarousel === 4 ? 'active' : ''}`}>

                  <div className="service-card swiper-slide">
                    <div className="service-card__media">
                      <img src={Administracao} alt="..." />
                    </div>
                    <div className="service-card__content">
                      <h3 className="service-card__title">Administração de fundos</h3>
                      <h4 className="service-card__subtitle">Investir em créditos de carbono se tornou uma alternativa
                        para mitigar os efeitos nocivos do efeito estufa no meio ambiente, bem como seu impacto
                        econômico.</h4>
                      <div className="service-card__text">
                        <p>Cada tonelada de gases que uma empresa deixa de emitir, gera um crédito de carbono. As
                          empresas vendem ou compram o excedente de carbono gerados a partir de projetos ambientais,
                          que certificam e validam os créditos de carbono. A administração desses fundos de
                          créditos de carbono visa auxiliar na compra e venda desse investimento, pois esses
                          movimentos acompanham a bolsa de valores. Dessa forma, o interessado pagará uma taxa para
                          que seus investimentos sejam administrados por uma equipe técnica e qualificada,
                          objetivando mais segurança no momento da transação, bem como validade daqueles créditos
                          adquiridos ou vendidos.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`carousel-item${activeIndexFirstCarousel === 5 ? 'active' : ''}`}>

                  <div className="service-card swiper-slide">
                    <div className="service-card__media">
                      <img src={Balanco} alt="..." />
                    </div>
                    <div className="service-card__content">
                      <h3 className="service-card__title">Balanço<br /> Socioambiental</h3>
                      <h4 className="service-card__subtitle">O balanço socioambiental é um levantamento dos principais
                        indicadores de desempenho ambiental, econômico e social de uma empresa.</h4>
                      <div className="service-card__text">
                        <p>A publicação deste estudo por parte da empresa, além de ampliar o seu diálogo com a
                          sociedade, ajuda-a compreender e gerenciar melhor seus impactos nas pessoas e no planeta,
                          podendo identificar e reduzir riscos, avaliar a eficiência de seus investimentos,
                          aproveitar novas oportunidades e agir para se tornar uma organização transparente e
                          confiável em um mundo mais sustentável.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <button className="carousel-control-prev" type="button" onClick={handleFirstCarouselPrev}>
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                </button>
                <button className="carousel-control-next" type="button" onClick={handleFirstCarouselNext}>
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                </button>
                <div className="servicos-paginacao servicos-paginacao-bullets servicos-paginacao-horizontal">
                <span
                  className={`servicos-paginacao-bullet${activeIndexFirstCarousel === 1 ? ' servicos-paginacao-bullet-active' : ''}`}
                  onClick={() => setActiveIndexFirstCarousel(1)}
                >1</span>
                <span
                  className={`servicos-paginacao-bullet${activeIndexFirstCarousel === 2 ? ' servicos-paginacao-bullet-active' : ''}`}
                  onClick={() => setActiveIndexFirstCarousel(2)}
                >2</span>
                <span
                  className={`servicos-paginacao-bullet${activeIndexFirstCarousel === 3 ? ' servicos-paginacao-bullet-active' : ''}`}
                  onClick={() => setActiveIndexFirstCarousel(3)}
                >3</span>
                <span
                  className={`servicos-paginacao-bullet${activeIndexFirstCarousel === 4 ? ' servicos-paginacao-bullet-active' : ''}`}
                  onClick={() => setActiveIndexFirstCarousel(4)}
                >4</span>
                <span
                  className={`servicos-paginacao-bullet${activeIndexFirstCarousel === 5 ? ' servicos-paginacao-bullet-active' : ''}`}
                  onClick={() => setActiveIndexFirstCarousel(5)}
                >5</span>
              </div>
              </div>
              
            </div>
            
          </div>
        </div>
      </section>
    </div>
  );
}
