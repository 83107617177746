import React, { useState } from "react";

// Contexts

// Functions

//Componentes
import { Swiper, SwiperSlide } from "../../components/Swiper";

//Chamada da api

// Interno

//Externos


//Assets
import TbHeader from "../../assets/img/tb-header.png";
import Projetos from "../../assets/img/_files/projetos.png";
import Certificacao from "../../assets/img/_files/certificacao.png";
import Registro from "../../assets/img/_files/registro.png";
import Monitoracao from "../../assets/img/_files/monitoracao.png";
import Compensacao from "../../assets/img/_files/compensacao.png";

// Styles
import "./styles.scss";

export default function Beneficios() {
  // Estado para acompanhar o índice do slide ativo para o primeiro bloco
  const [activeIndexFirstCarousel, setActiveIndexFirstCarousel] = useState(1);

  // Estado para acompanhar o índice do slide ativo para o segundo bloco
  const [activeIndexSecondCarousel, setActiveIndexSecondCarousel] = useState(1);


  const handleFirstCarouselPrev = () => {
    setActiveIndexFirstCarousel(prevIndex => (prevIndex === 1 ? 6 : prevIndex - 1));
  };

  const handleFirstCarouselNext = () => {
    setActiveIndexFirstCarousel(prevIndex => (prevIndex === 6 ? 1 : prevIndex + 1));
  };

  const handleSecondCarouselPrev = () => {
    setActiveIndexSecondCarousel(prevIndex => (prevIndex === 1 ? 7 : prevIndex - 1));
  };

  const handleSecondCarouselNext = () => {
    setActiveIndexSecondCarousel(prevIndex => (prevIndex === 7 ? 1 : prevIndex + 1));
  };

  return (
    <section className="beneficios">
      <div className="wrapper">

        <section className="row" id="tipos-de-credito">

          <div className="col-12 col-md-6">

            <div className="bloco-benefits">
              <div className="tb-header">
                <h2 className="tb-title">Tipos de crédito<br /> de carbono</h2>
                <img src={TbHeader} alt="..." />
              </div>
              <div className="tb-block carousel slide">
                <div className="carousel-inner">
                  <div className={`carousel-item${activeIndexFirstCarousel === 1 ? ' active' : ''}`}>
                    <div className="tb-card">
                      <h3 className="tb-card__title">Créditos de Redução de Emissões (CERs)</h3>
                      <div className="tb-card__text">
                        <p>Àquele que pretende gerar créditos de carbono o poderá fazê-lo a partir de ações que
                          reduzam ou eliminem a emissão de gases de efeito estufa, por exemplo:</p>
                        <p>– Substituição de fontes energéticas não renováveis;<br />
                          – Redução dos níveis de desmatamento;<br />
                          -Preservação e restauração de biomas.</p>
                        <p>Os créditos de carbono possuem naturezas diferentes, no mercado regulado,
                          será monitorado pelos governos, enquanto no mercado voluntário,
                          depende do interesse de particulares, para comprar e vender de forma independente.</p>
                      </div>
                    </div>
                  </div>
                  <div className={`carousel-item${activeIndexFirstCarousel === 2 ? ' active' : ''}`}>
                    <div className="tb-card">
                      <h3 className="tb-card__title">Créditos de Redução de Emissões Voluntárias (VERs)</h3>
                      <div className="tb-card__text">
                        <p>São gerados por projetos voluntários de redução de emissões,
                          geralmente realizados em países desenvolvidos ou em desenvolvimento,
                          mas que não estão vinculados a acordos internacionais formais.
                          Empresas, organizações e indivíduos podem adquirir esses créditos
                          como parte de suas estratégias de responsabilidade ambiental.</p>
                      </div>
                    </div>
                  </div>
                  <div className={`carousel-item${activeIndexFirstCarousel === 3 ? ' active' : ''}`}>
                    <div className="tb-card">
                      <h3 className="tb-card__title">Créditos de Remoção de Emissões (REDD+)</h3>
                      <div className="tb-card__text">
                        <p>Estão associados a projetos de conservação e reflorestamento que
                          contribuem para a remoção de CO2 da atmosfera, geralmente em países
                          com grandes áreas de florestas tropicais. Esses créditos são baseados na prevenção
                          do desmatamento e na proteção das florestas existentes.</p>
                      </div>
                    </div>
                  </div>
                  <div className={`carousel-item${activeIndexFirstCarousel === 4 ? ' active' : ''}`}>
                    <div className="tb-card">
                      <h3 className="tb-card__title">Créditos de Captura e Armazenamento de Carbono (CCS)</h3>
                      <div className="tb-card__text">
                        <p>São gerados por projetos que capturam CO2 de fontes industriais ou
                          de geração de energia e o armazenam de forma permanente em
                          locais geologicamente seguros, como reservatórios subterrâneos.</p>
                      </div>
                    </div>
                  </div>
                  <div className={`carousel-item${activeIndexFirstCarousel === 5 ? ' active' : ''}`}>
                    <div className="tb-card">
                      <h3 className="tb-card__title">Créditos de Energias Renováveis</h3>
                      <div className="tb-card__text">
                        <p>Podem ser obtidos através de projetos de geração de energia
                          a partir de fontes renováveis, como energia solar, eólica, hidrelétrica,
                          biomassa, entre outras. Esses projetos reduzem as emissões de carbono
                          associadas à produção de energia a partir de combustíveis fósseis.</p>
                      </div>
                    </div>
                  </div>
                  <div className={`carousel-item${activeIndexFirstCarousel === 6 ? ' active' : ''}`}>
                    <div className="tb-card">
                      <h3 className="tb-card__title">Créditos de Eficiência Energética</h3>
                      <div className="tb-card__text">
                        <p>São gerados por projetos que promovem o uso mais eficiente de energia,
                          reduzindo o consumo e,
                          consequentemente, as emissões de carbono.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <button className="carousel-control-prev" type="button" onClick={handleFirstCarouselPrev}>
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                </button>
                <button className="carousel-control-next" type="button" onClick={handleFirstCarouselNext}>
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                </button>

              </div>
              <div className="beneficios-paginacao beneficios-paginacao-bullets beneficios-paginacao-horizontal">
                <span
                  className={`beneficios-paginacao-bullet${activeIndexFirstCarousel === 1 ? ' beneficios-paginacao-bullet-active' : ''}`}
                  onClick={() => setActiveIndexFirstCarousel(1)}
                >1</span>
                <span
                  className={`beneficios-paginacao-bullet${activeIndexFirstCarousel === 2 ? ' beneficios-paginacao-bullet-active' : ''}`}
                  onClick={() => setActiveIndexFirstCarousel(2)}
                >2</span>
                <span
                  className={`beneficios-paginacao-bullet${activeIndexFirstCarousel === 3 ? ' beneficios-paginacao-bullet-active' : ''}`}
                  onClick={() => setActiveIndexFirstCarousel(3)}
                >3</span>
                <span
                  className={`beneficios-paginacao-bullet${activeIndexFirstCarousel === 4 ? ' beneficios-paginacao-bullet-active' : ''}`}
                  onClick={() => setActiveIndexFirstCarousel(4)}
                >4</span>
                <span
                  className={`beneficios-paginacao-bullet${activeIndexFirstCarousel === 5 ? ' beneficios-paginacao-bullet-active' : ''}`}
                  onClick={() => setActiveIndexFirstCarousel(5)}
                >5</span>
                <span
                  className={`beneficios-paginacao-bullet${activeIndexFirstCarousel === 6 ? ' beneficios-paginacao-bullet-active' : ''}`}
                  onClick={() => setActiveIndexFirstCarousel(6)}
                >6</span>
              </div>
            </div>

          </div>


          {/* Segundo Bloco */}
          <div className="col-12 col-md-6">
            <div className="bloco-benefits">
              <div className="tb-header">
                <h2 className="tb-title">Benefícios do<br /> crédito de carbono</h2>
                <img src={TbHeader} alt="..." />
              </div>
              <div id="carouselBeneficiosCarbono" className=" tb-block carousel slide">
                <div className="carousel-inner">
                  <div className={`carousel-item${activeIndexSecondCarousel === 1 ? ' active' : ''}`}>
                    <div className="tb-card">
                      <h3 className="tb-card__title">Redução de Emissões</h3>
                      <div className="tb-card__text">
                        <p>O principal benefício do sistema de créditos de carbono
                          é que ele fornece um incentivo financeiro para as empresas
                          e organizações reduzirem suas emissões de gases de efeito estufa.
                          Isso ajuda a acelerar a adoção de práticas mais sustentáveis
                          e a transição para tecnologias de baixo carbono.</p>
                      </div>
                    </div>
                  </div>
                  <div className={`carousel-item${activeIndexSecondCarousel === 2 ? ' active' : ''}`}>
                    <div className="tb-card">
                      <h3 className="tb-card__title">Compensação de Emissões</h3>
                      <div className="tb-card__text">
                        <p>Empresas e indivíduos podem usar créditos de carbono
                          para compensar suas próprias emissões, investindo em projetos
                          que removam ou reduzam dióxido de carbono da atmosfera. Isso pode ajudar a neutralizar impactos
                          ambientais enquanto se trabalha na redução direta das emissões.</p>
                      </div>
                    </div>
                  </div>
                  <div className={`carousel-item${activeIndexSecondCarousel === 3 ? ' active' : ''}`}>
                    <div className="tb-card">
                      <h3 className="tb-card__title">Responsabilidade Ambiental</h3>
                      <div className="tb-card__text">
                        <p>Participar de iniciativas de crédito de carbono demonstra comprometimento
                          com a responsabilidade ambiental e a sustentabilidade. Isso pode melhorar
                          a imagem pública das empresas e atrair consumidores conscientes.</p>
                      </div>
                    </div>
                  </div>
                  <div className={`carousel-item${activeIndexSecondCarousel === 4 ? ' active' : ''}`}>
                    <div className="tb-card">
                      <h3 className="tb-card__title">Mercado de Créditos Carbono</h3>
                      <div className="tb-card__text">
                        <p>O sistema de créditos de carbono cria um mercado para
                          a negociação de créditos, permitindo que empresas que excedam suas
                          metas de redução vendam seus créditos para aquelas que têm dificuldade
                          em cumprir suas metas. Isso cria oportunidades de receita
                          para pessoas físicas e jurídicas que conseguem reduzir suas emissões.</p>
                      </div>
                    </div>
                  </div>
                  <div className={`carousel-item${activeIndexSecondCarousel === 5 ? ' active' : ''}`}>
                    <div className="tb-card">
                      <h3 className="tb-card__title">Novos Projetos</h3>
                      <div className="tb-card__text">
                        <p>Os recursos provenientes da venda de créditos de carbono podem ser direcionados
                          para financiar projetos de energia renovável, eficiência energética e outras
                          iniciativas sustentáveis.
                          Isso pode ajudar a impulsionar a transição para uma economia de baixo carbono.</p>
                      </div>
                    </div>
                  </div>
                  <div className={`carousel-item${activeIndexSecondCarousel === 6 ? ' active' : ''}`}>
                    <div className="tb-card">
                      <h3 className="tb-card__title">Inovação e Tecnologia</h3>
                      <div className="tb-card__text">
                        <p>A busca por redução de emissões para obter créditos de carbono
                          pode impulsionar a inovação tecnológica. Empresas são motivadas
                          a desenvolver e adotar tecnologias mais eficientes e limpas,
                          promovendo avanços na mitigação das mudanças climáticas.</p>
                      </div>
                    </div>
                  </div>
                  <div className={`carousel-item${activeIndexSecondCarousel === 7 ? ' active' : ''}`}>
                    <div className="tb-card">
                      <h3 className="tb-card__title">Energias Renováveis</h3>
                      <div className="tb-card__text">
                        <p>Com a venda de créditos de carbono, projetos de energia renovável
                          podem se tornar mais financeiramente viáveis, acelerando a transição
                          de fontes de energia fósseis para fontes limpas e renováveis.</p>
                      </div>
                    </div>
                  </div>

                </div>
                <button className="carousel-control-prev" type="button" onClick={handleSecondCarouselPrev}>
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                </button>
                <button className="carousel-control-next" type="button" onClick={handleSecondCarouselNext}>
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                </button>
              </div>
              
            </div>
            <div className="beneficios-paginacao beneficios-paginacao-bullets beneficios-paginacao-horizontal">
                <span
                  className={`beneficios-paginacao-bullet${activeIndexSecondCarousel === 1 ? ' beneficios-paginacao-bullet-active' : ''}`}
                  onClick={() => setActiveIndexSecondCarousel(1)}
                >1</span>
                <span
                  className={`beneficios-paginacao-bullet${activeIndexSecondCarousel === 2 ? ' beneficios-paginacao-bullet-active' : ''}`}
                  onClick={() => setActiveIndexSecondCarousel(2)}
                >2</span>
                <span
                  className={`beneficios-paginacao-bullet${activeIndexSecondCarousel === 3 ? ' beneficios-paginacao-bullet-active' : ''}`}
                  onClick={() => setActiveIndexSecondCarousel(3)}
                >3</span>
                <span
                  className={`beneficios-paginacao-bullet${activeIndexSecondCarousel === 4 ? ' beneficios-paginacao-bullet-active' : ''}`}
                  onClick={() => setActiveIndexSecondCarousel(4)}
                >4</span>
                <span
                  className={`beneficios-paginacao-bullet${activeIndexSecondCarousel === 5 ? ' beneficios-paginacao-bullet-active' : ''}`}
                  onClick={() => setActiveIndexSecondCarousel(5)}
                >5</span>
                <span
                  className={`beneficios-paginacao-bullet${activeIndexSecondCarousel === 6 ? ' beneficios-paginacao-bullet-active' : ''}`}
                  onClick={() => setActiveIndexSecondCarousel(6)}
                >6</span>
                <span
                  className={`beneficios-paginacao-bullet${activeIndexSecondCarousel === 7 ? ' beneficios-paginacao-bullet-active' : ''}`}
                  onClick={() => setActiveIndexSecondCarousel(7)}
                >7</span>
              </div>
          </div>
          
        </section>


        <section className="row descarbonizacao" id="esteira-da-descarbonizacao">
          <div className="col-12 col-md-10 col-lg-7 wrapper">
            <h2 className="dw-title">Esteira da descarbonização</h2>

            <Swiper
              spaceBetween={0}
              slidesPerView={5}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                400: {
                  slidesPerView: 2,
                },
                639: {
                  slidesPerView: 3,
                },
                865: {
                  slidesPerView: 4,
                },
                1000: {
                  slidesPerView: 5,
                },
                1500: {
                  slidesPerView: 5,
                },
                1700: {
                  slidesPerView: 5,
                }
              }}
              className="swiper-wrapper"
            >
              <SwiperSlide className="dw-slider"><img src={Projetos} alt="Projetos" /></SwiperSlide>
              <SwiperSlide className="dw-slider"><img src={Certificacao} alt="Certificação" /></SwiperSlide>
              <SwiperSlide className="dw-slider"><img src={Registro} alt="Registro" /></SwiperSlide>
              <SwiperSlide className="dw-slider"><img src={Monitoracao} alt="Monitoração" /></SwiperSlide>
              <SwiperSlide className="dw-slider"><img src={Compensacao} alt="Compensação" /></SwiperSlide>
            </Swiper>
            {/* <div className="swiper-pagination"></div> */}
          </div>
        </section>

      </div>
    </section>
  );
}
