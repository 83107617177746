import React, { useEffect, useState, useRef } from 'react'; 
import Slider from 'react-slick';
import PdfDecokatsu from "../../assets/docs/decokatsu_20241007.pdf";
import { pdfjs } from 'react-pdf';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Styles
import "./styles.scss";

export default function ModalDecokatsu() {
    const [numPaginas, setNumPaginas] = useState(null);
    const [numerosPaginas, setNumerosPaginas] = useState([]);
    const [imagens, setImagens] = useState([]);
    const [paginaAtual, setPaginaAtual] = useState(0);

    // Referência para o Slider
    const sliderRef = useRef(null);

    useEffect(() => {
        const carregamento = pdfjs.getDocument(PdfDecokatsu);
        carregamento.promise.then(pdf => {
            setNumPaginas(pdf.numPages);
            const paginas = Array.from({ length: pdf.numPages }, (_, i) => i + 1);
            setNumerosPaginas(paginas);

            // Gerar as imagens
            Promise.all(paginas.map((numeroPagina) => renderizarPagina(pdf, numeroPagina)))
                .then(setImagens);
        });
    }, []);


    // Renderiza a página
    const renderizarPagina = async (pdf, numeroPagina) => {
        const pagina = await pdf.getPage(numeroPagina);
        const viewport = pagina.getViewport({ scale: 2 });
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        const contextoRenderizacao = {
            canvasContext: context,
            viewport: viewport,
        };
        await pagina.render(contextoRenderizacao).promise; // Aguardar a renderização

        return canvas.toDataURL(); // Retornar a imagem
    };


    // Atualizar a página atual
    const atualizarPagina = (_, newIndex) => {
        setPaginaAtual(newIndex); 
    };

    // Volta para o slide anterior
    const handlePrev = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev();
        }
    };

    // Muda para o próximo slide
    const handleNext = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext(); 
        }
    };

    return (
        <div className="modal-content">
            <div className="modal-header d-flex justify-content-center">
                <h5 className="modal-title text-center">Decokatsu</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                    <h5><b>x</b></h5>
                </button>
            </div>
            <div className='img-modal-decokatsu'>
                <Slider
                    ref={sliderRef}  // Associa o slider à referência
                    dots={true}
                    infinite={false}
                    beforeChange={atualizarPagina}
                    arrows={false}  // Desabilita as setas padrão
                >
                    {imagens.map((imagem, index) => (
                        <div key={index} className='d-flex justify-content-center container-img-decokatsu'>
                            <img src={imagem} alt={`Página ${numerosPaginas[index]}`} />
                        </div>
                    ))}
                </Slider>

                {/* Botões personalizados para controle */}
                <button className="carousel-control-prev" type="button" onClick={handlePrev}>
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                </button>
                <button className="carousel-control-next" type="button" onClick={handleNext}>
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                </button>

                <div className="text-center" style={{marginTop:"2.2rem"}}>
                    <h6>
                        Página {paginaAtual + 1} de {numPaginas}
                    </h6>
                </div>
            </div>
        </div>
    );
}
