import React, { useEffect, useState } from "react";

// Contexts

// Functions

//Componentes
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import QuemSomos from "../QuemSomos";
import Beneficios from "../Beneficios";
import ProdutosServicos from "../ProdutosServicos";
import Faq from "../Faq";
import Manutencao from "../../components/Manutencao";

//Chamada da api

// Interno

//Externos

//Assets


// Styles
import "./styles.scss";

export default function Home() {
  const [telaManutencao, setTelaManutencao] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <>{telaManutencao ? <Manutencao /> :
      <div>
        <Header scrollToTop={scrollToTop} />
        <QuemSomos />
        <Beneficios />
        <ProdutosServicos />
        <Faq />
        <Footer scrollToTop={scrollToTop}/>
      </div >
    }

    </>


  );
}
