import React from 'react';

import Logo from "../../assets/img/logo.png";
import ManutencaoImg from "../../assets/img/manutencao.PNG";

import "./styles.scss"
export default function Manutencao() {
    return (
        <div id="container-manutencao">
            <header className="logo">
                <img src={Logo} alt="Logo Safecarbon" />
            </header>
            <div className="container-manutencao_interno">
                <div class="container-manutencao">
                    <div className="container-manutencao__text">
                        <h1><b>ESTAMOS EM MANUTENÇÃO!</b></h1>
                        <br />
                        <p>VOLTAREMOS EM BREVE!</p>
                    </div>

                    <div className="d-flex justify-content-between">
                        <img className="manutencao_img" src={ManutencaoImg}
                            alt="Site fora do ar em manutecao" />

                    </div>
                </div>
            </div>
        </div>
    )
}